import React from 'react'

const About = () => (
  <section id="one">
    <header className="major">
      <h2>ABOUT</h2>
    </header>
    <p>
      I do quite a bit of community work and public speaking you’ll often find
      me at user groups in Bangkok.
    </p>
  </section>)

export default About;